/* eslint-disable */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Top from './Top';
import Footer from './Footer';
import axios from 'axios';
const config = require('./config');
var helper = require("./Helper.js");
//import { helper } from './Helper'

//const lang = require('./language/lang-'+config.language);
const APIURL = config.path.apiUrl;
const loginUser = config.login_user;
var qs = require('qs');

class Checkout extends Component {
	constructor(props, context) {
	 	super(props, context);
		this.state = {
        	cartResult: helper.htmlConvert('<tr><td colspan="5">Loading</td></tr>'), cartDetails: [], checkoutDetails:'', checkouturl:'#',
        };

	}
	componentWillMount() {
		this.loadCartDetails();
	}

	loadCartDetails() {
        axios.get(APIURL+'userCart/loadCartDetails?userID='+loginUser).then((response) => {
            this.setState({productLoadStatus:false});
            if(response.data.status === "ok"){
                var result = response.data.result;
                this.setState({cartDetails:result}, function () { this.loadCartLink(result); }.bind(this));
                this.setState({cartResult:this.loadcart(result)});
                this.top.loadCartDetails();
                
            }
        });
    }
    loadCartLink(cartDetails) {
    	var cartDetails = this.state.cartDetails[0];
    	
    	axios.post(APIURL+'userCart/loadCheckoutDetails', qs.stringify({userID: loginUser, cartID:cartDetails.cart_id, storeID:4})).then(response => {
            if(response.data.status === "ok"){
                var result = response.data.result;
                 this.setState({checkoutDetails:result});
                 this.setState({checkouturl:result.checkout_url});                 
           
            }
        });
    }

    
	loadcart(cart) {
        return cart.map((loaddata, index) =>
                loaddata.cart_items.map((loaddata1, index1) =>
                <tr key={index1}>
                <td>
                <img src={(typeof loaddata1.product_image!=='undefined' && loaddata1.product_image!=='' && loaddata1.product_image!==null)?loaddata1.product_image:'assets/images/no-image.png'} alt="" />
                </td>
                <td>{loaddata1.product_name}
                </td>
                <td>
                {loaddata1.upsc}
                </td>
                
                <td>{loaddata1.quantity}</td>
                <td><a href="javascript:" onClick={this.top.removecartItem.bind(this, loaddata1.item_id, loaddata1.cart_id)}>Remove</a></td>
                </tr>
                )
            );
     }
	

	scrollStep() {
        if (window.pageYOffset === 0) {
            clearInterval(this.state.intervalId);
        }
        window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
    }
    scrollToTop() {
        let intervalId = setInterval(this.scrollStep.bind(this), this.props.delayInMs);
        this.setState({ intervalId: intervalId });
    }

	 render() {
	 	var currentLocation = {paths:'no'};
	 	return(
		 	<div>
		 		 <Top {...currentLocation} onRef={ref => (this.top = ref)} />
				<div className="container_wrap">
					<div className="search_component">
							<div className="price-history">
								<div className="offer-table-component">
									<table className="table table-bordered">
										<thead>
											<tr>
												<th>Image</th>
												<th>Product Name</th>
												<th>UPSC</th>
												<th>Quantity</th>
												<th>Remove</th>
											</tr>
										</thead>
										<tbody>
											{this.state.cartResult}											
										</tbody>
									</table>
									<div className="action-group">
									<Link to={'/products'}><button className="primary-btn-large"><span>Continue to Shopping</span></button></Link>
									<a href={this.state.checkouturl} target="_blank"><button className="primary-btn-large"><span>Checkout By Amazon</span></button></a>
									</div>
								</div>
							</div>
						</div>
					</div>
				<Footer {...currentLocation} />
		 	</div>
	 	);
	}
}

export default Checkout;