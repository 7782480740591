module.exports = {
	/* all path values  */
	/* Ezzysales */
	path: {
		baseUrl	: 'http://localhost:3000/#/',
		apiUrl 	: 'https://demo.ezzysales.com/bynn/v1/',
	},
	/* Local */
	/*path: {
		baseUrl	: 'http://localhost:3000/#/',
		apiUrl 	: 'http://192.168.2.106/bynn/v1/',
	},*/

	login_user: 'Praba',
	language:'en',
};
