import React from 'react';
import ReactDOM from 'react-dom';
import { Route} from 'react-router';
import { HashRouter } from 'react-router-dom';
import Productlist from './Productlist';
import Product from './Product';
import Checkout from './Checkout';


ReactDOM.render((
  <HashRouter>
      <div>
      <Route exact path="/" component={Productlist} ignoreScrollBehavior initial={true}  />
        <Route exact path="/products/" component={Productlist} ignoreScrollBehavior initial={true}  />
        <Route exact path="/products/:id" component={Productlist} ignoreScrollBehavior initial={true}  />
        <Route exact path="/product/" component={Product} ignoreScrollBehavior initial={true}  />
        <Route exact path="/checkout/" component={Checkout} ignoreScrollBehavior initial={true}  />     
      </div>
   </HashRouter >
  ),
  document.getElementById('app')
);