/* eslint-disable */
import React, { Component } from 'react';
import cookie from 'react-cookies';
import { Link } from 'react-router-dom';
var HtmlToReactParser = require('html-to-react').Parser;
var htmlToReactParserTo = new HtmlToReactParser();
const config = require('./config');
const APIURL = config.path.apiUrl;
const lang = require('./language/lang-'+config.language);


/*   Show price format  */
export function showPrice(price) {
  if(isNaN(price)){
   return "";
 }
 else{
  if(price<=0) {
    price = 0;
  }
  return parseFloat(price).toFixed(2);
}
}
/*   Show price format  */
export function currency(space) {
	var html = " $";
	return html;
}
export function emailValidate(eamilID) {

	var re =  /[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/igm;
	if (eamilID !== '' && !re.test(eamilID)) return 1;
	else return 0;
}

export function htmlConvert(message) {
  if(message!='') {
    return htmlToReactParserTo.parse(message);
  }
}

/* Get  loading iocn  */
export function getSuccess(message) {
  var html =  '<div className="cart-success" id="cart_success">'+message+'</div>';
  return htmlToReactParserTo.parse(html );
}

export function getshowSuccess(type) {
  var x = document.getElementById("cart_success");
  if (type === "show") {
    x.style.display = "block";
  } else {
    x.style.display = "none";
  }
}

export function gethideSuccess(type) {
  setTimeout(
    function() {
      document.getElementById('cart_success').style.display='none';
    }, 5000);
}
