/* eslint-disable */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import cookie from 'react-cookies';
import axios from 'axios';
const config = require('./config');
const lang = require('./language/lang-'+config.language);
const APIURL = config.path.apiUrl;
const loginUser = config.login_user;

var qs = require('qs');
class Top extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            searchKeyword:this.props.searchKeyword, cartResult: 'No Record Found', cartDetails: [], no_items:0,
        };
    }
   
    componentWillMount() {
        //this.loadCartDetails();        
    }
    componentDidMount() {
        this.props.onRef(this);
    }
    componentWillUnmount() {
         this.props.onRef(undefined)
    }

    handleChangeTxt = (item, event) => {
        this.setState({ [item]:event.target.value });
    }
    searchProduct() {
        location.href = "#/products?s="+this.state.searchKeyword;
        location.reload();
    }
    enterPressed(event) {
        var code = event.keyCode || event.which;
        if(code === 13) { //13 is the enter keycode
            this.searchProduct();
        } 
    }

    loadCartDetails() {
        axios.get(APIURL+'userCart/loadCartDetails?userID='+loginUser).then((response) => {
            this.setState({productLoadStatus:false});
            if(response.data.status === "ok"){
                var result = response.data.result;
                this.setState({cartDetails:result});
                this.setState({no_items:response.data.no_items});
                this.setState({cartResult:this.loadcart(result)});
            }
        });
    }
    loadcart(cart) {

        /*return cart.map((loaddata, index) =>
                loaddata.cart_items.map((loaddata1, index1) =>
                    <div key={index1} className="cart-item-row">
                        <div className="cart-item-desc">
                            <div className="cart-img"><img src={(typeof loaddata1.product_image!=='undefined' && loaddata1.product_image!=='' && loaddata1.product_image!== null)?loaddata1.product_image:'assets/images/no-image.png'}  alt="" /></div>
                            <div className="cart-name">
                                <h5>{loaddata1.product_name}</h5>
                            </div>
                        </div>
                        <div className="cart-item-details">
                            <div className="cart-price"> 
                                <p>$27.00</p> <span className="cart-delete" onClick={this.removecartItem.bind(this, loaddata1.item_id, loaddata1.cart_id)}></span>
                            </div>
                            <div className="qty_bx">
                                <span className="page_lefter">-</span> 
                                <input type="text" defaultValue="1" /> 
                                <span className="page_righter">+</span> 
                            </div>                              
                        </div>
                    </div>
                )
            );*/
    }
    removecartItem(item_id, cart_id) {
        if (window.confirm("Do you really want to leave?")) { 
            axios.post(APIURL+'userCart/removeCartItem', qs.stringify({userID: loginUser, cartID:cart_id, cartItemId:item_id})).then(response => {
                if(response.data.status === "ok"){
                    var result = response.data.result;
                    this.loadCartDetails();
                }
            });
        }

 
    }
  
    render() {
        return (
        <header>
        <div className="header">
            <div className="container_wrap">

                <div className="top_header">
                    <div className="logo"><a href="#"><img src="/assets/images/logo.png" alt="" /></a></div>
                    <div className="product_search_box">
                        <div className="search-input-container">
                            <input className="form-control" defaultValue={this.state.searchKeyword} onChange={this.handleChangeTxt.bind(this, 'searchKeyword')} onKeyPress={this.enterPressed.bind(this)}  />
                           <div className="icon-search" onClick={this.searchProduct.bind(this)} ></div>
                        </div>
                    </div>
                </div>
                
                <div className="main_header">
                    <nav className="navbar-main">  
                            <div className="navbar-collapsed" id="mobi-collapse">
                                <ul className="navs">
                                    <li><a href="#">Stores</a></li>
                                    <li><Link to={'/'}>Products</Link></li>
                                    <li><a href="#">Discovers</a></li>
                                    <li><a href="#">Watchlist</a></li>
                                    <li><a href="#">Redeem</a></li>
                                </ul>
                                <ul className="navs navs-right">
                                    <li><a href="#">Eli</a></li>
                                    <li><a href="#">Help</a></li>
                                    <li style={{display:'none'}}><a href="#"><i className="fa fa-shopping-cart"></i><span className="cart-count cart_order_count">{this.state.no_items}</span></a>                               
                                        <ul className="cart-ul">
                                            <div className="cart-item">
                                                <div className="cart-header"> 
                                                    <h4> Added Items </h4> <a href="/"> CLEAR CART </a>
                                                </div>
                                                <div className="cart-item-body">
                                                    {this.state.cartResult}
                                                    <div className="cart-item-total" style={{display:(this.state.no_items>0)?'block':'none'}}>
                                                        <div className="total-row alltot">
                                                            <div className="item-lebel">
                                                                <p>Total</p>
                                                            </div>
                                                            <div className="amount">
                                                                <p>$37.00</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="checkout">
                                                    <div className="btn-left"><Link to={'/checkout'}>Checkout Now</Link></div>
                                                   </div>
                                            </div>
                                        </ul>
                                    </li>


                                </ul>
                            </div>
                        </nav>
                    </div>

                </div>
            </div>  
        </header>   
    );
  }
}

export default Top;
