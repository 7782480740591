/* eslint-disable */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
//const APIURL = config.path.apiUrl;



class Footer extends Component {
    constructor(props, context) {
        super(props, context);
    }
   


  render() {

    return (
     <footer>
        <div className="container_wrap">
            <div className="page-footer">
                <div className="about">
                    <p>bynn gets you better offers, automatically applies <br/> the best coupon code at checkout, and lets you know <br/> when prices drop on products you've viewed and purchased.</p>
                    <p>© 2019 Bynn</p>
                    <div className="social-icons">
                        <a className="icon icon-facebook"></a>
                        <a className="icon icon-twitter"></a>
                    </div>
                </div>          
                <div className="links-section">     
                    <div className="app-links">
                        <ul>
                            <li><a href="/">Privacy Policy</a></li>
                            <li><a href="/">Terms of Service</a></li>
                            <li><a href="/">Help Center</a></li>
                            <li><a href="mailto:help@bynnbuy.com" target="_top">help@bynnbuy.com</a></li>
                        </ul>
                    </div>      
                    <div className="external-link">
                        <ul>
                            <li><a href="/instant">Get bynn</a></li>
                            <li><a href="/blog">Blog</a></li>
                            <li><a href="/trending/deals">Trending Deals</a></li>
                            <li><a href="/sitemap">Sitemap</a></li>
                        </ul>
                    </div>      
                </div>          
            </div>
        </div>
    </footer>
    );
  }
}

export default Footer;
