/* eslint-disable */
import React, { Component } from 'react';
import Top from './Top';
import Footer from './Footer';
import axios from 'axios';
const config = require('./config');
var helper = require("./Helper.js");
import { BulletList } from 'react-content-loader'
//import { helper } from './Helper'

//const lang = require('./language/lang-'+config.language);
const APIURL = config.path.apiUrl;
const loginUser = config.login_user;
var qs = require('qs');

class Product extends Component {
	constructor(props, context) {
	 	super(props, context);
	 	const urlParams = new URLSearchParams(this.props.location.search)
		const productId = urlParams.get('id');
		this.state = {
        	productId:productId, productDetails: [], cartDetails:[],cartSucces:'', googlelist:'', amazonelist:'', productPriceLoadStatus:true, totalOffers:0, productprice:0, productDescription:'', productDescriptionDetails:'', showDesc:0,
        };

	}
	componentWillMount() {
		//this.loadCartDetails();
	 	this.loadProduct();
	 	this.scrollStep();
	}

	loadProduct() {
	 	axios.post(APIURL+'products/productDetails', qs.stringify({productID: this.state.productId})).then(response => {
	 		if(response.data.status === "ok"){
	 			var result = response.data.result;
				this.setState({productDetails:response.data.result});
				if(result.upc!=='' & typeof result.upc!=='undefined') {
					this.loadProductPrice(result.upc);	
				}
				
	 		}
        }).catch(function (error) {
            console.log(error);
        });;
	}
	loadProductPrice(upc) {
		if(upc!=='') {
			this.setState({productPriceLoadStatus:true});
			var productsLoadStatus = 0;
			axios.post(APIURL+'products/productPriceApi', qs.stringify({upc: upc})).then(response => {
				this.setState({productPriceLoadStatus:false});
		 		if(response.data.status === "ok"){


		 			var result = response.data.datas;
		 			if(typeof result.amazon!=='undefined') {
		 				if(result.amazon.reason==='job not finished') {
		 					productsLoadStatus++;
		 				}
		 				else {

		 					this.setState({amazonelist:this.listProducts(result.amazon, 'amazon')});
		 				}
		 			}

		 			if(typeof result.google_shopping!=='undefined') {
		 				if(result.google_shopping.reason==='job not finished') {
		 					productsLoadStatus++;
		 				}
		 				else {
		 					this.setState({googlelist:this.listProducts(result.google_shopping, 'google_shopping')});
		 				}
		 			}
		 			
		 			
		 			if(productsLoadStatus>0) {
		 				this.setState({totalOffers:0});
	 					this.loadProductPrice(upc);
	 				}
	 				if(productsLoadStatus==0) {
	 					this.setState({productPriceLoadStatus:false});
	 				}

		 			
					//this.setState({productDetails:response.data.result});
					//this.ProductPrice(result.upc)
		 		}
	        }).catch(function (error) {
	            console.log(error);
	        });;
		}
		
	}
	showDesc() {
		if(this.state.showDesc===1) {
			this.setState({showDesc:0});
		}
		else {
			this.setState({showDesc:1});
		}
	}
	

	listProducts(shopDetails, types) {
		var shopprice =  shopDetails.results[0].content;
		if(typeof shopprice!=='undefined' && shopprice!='' && shopprice!==null) {
			var listshopPrice = shopprice.offers;
			this.setState({totalOffers:(parseInt(this.state.totalOffers)+parseInt(listshopPrice.length))});
			if(types==='google_shopping') {
				this.setState({productprice:shopprice.price});
			}
			if(this.state.productDescription==='' && shopprice.description!=='' && shopprice.description!==null) {
				this.setState({productDescription:this.loadDescription(shopprice.description)});
				;			
			}

			return listshopPrice.map((shoppricing, index) =>
				<tr key={index}>
					<td>
						<div className="vendor">
							<h4><span className="verified-dot verified"></span><span className="charcoal"><a href={(types==='amazon')?shopprice.url:shoppricing.url} target="_blank">{(types==='amazon')?'Amazon - ':''}{shoppricing.shop_name}</a></span></h4>
						</div>
					</td>
					<td>
						<div className="condition">
							<h4 className="charcoal">{shoppricing.condition_code}</h4>
						</div>
					</td>
					<td>
						<div className="delivery">
							<h4 className="charcoal">${helper.showPrice(shoppricing.price)}</h4>
						</div>
					</td>
					<td >
						<div className="sp-offer">
							<h4 className="charcoal">{(shoppricing.shipping_costs>0)?'$'+helper.showPrice(shoppricing.shipping_costs):'Free'}</h4>
						</div>
					</td>
					<td align="right">
						<div className="est-total">
							<h4 className="bold charcoal">${(shoppricing.price_with_shipping>0)?helper.showPrice(shoppricing.price_with_shipping):helper.showPrice(shoppricing.price)}</h4>
						</div>
					</td>
					<td className="action-group">
						<a href={(types==='amazon')?shopprice.url:shoppricing.url} target="_blank"><button className="primary-btn-large"><span>Buy Now</span></button></a>
					</td>
				</tr>
				);
		}
	}

	loadDescription(description) {
		return description;

		/*if(description!=='') {
			var description = description.split('Frequently Asked Questions');
			if(description[0]!=='' && typeof description[0]!='undefined') {
				var description1 = description[0].split('*');
				const desc =  description1.map(function(category, index){
			 	return (
			 			<li key={index} >
						{category}
						</li>
			 			);
			 	});
			 	return desc;
			}			
		}	*/
	}
	loadCartDetails() {
		axios.get(APIURL+'userCart/loadCartDetails?userID='+loginUser).then((response) => {
	 		this.setState({productLoadStatus:false});
	 		if(response.data.status === "ok"){
				this.setState({cartDetails:response.data.result});
	 		}
        });
	}

	addToCart() {
		if(typeof this.state.cartDetails!=='undefined' && typeof this.state.cartDetails[0]!=='undefined' && typeof this.state.cartDetails[0].cart_id!=='undefined') {
			axios.post(APIURL+'userCart/CreateCartItemNew', qs.stringify({userID: loginUser, quantity:1, upc:this.state.productDetails.upc, cartID:this.state.cartDetails[0].cart_id, product_name:this.state.productDetails.name, product_image:this.state.productDetails.small_image, product_id:this.state.productDetails.id})).then(response => {
		 		if(response.data.status === "ok"){
		 			this.loadCartDetails();
		 			this.setState({cartSucces:'Cart Added Successfully'});
		 			helper.getshowSuccess('show');
		 			helper.gethideSuccess('');
		 			
		 			this.top.loadCartDetails();

		 		}
	        }).catch(function (error) {
	            console.log(error);
	        });
		}
		else {
			axios.post(APIURL+'userCart/createCart', qs.stringify({userID: loginUser, quantity:1, upc:this.state.productDetails.upc, product_name:this.state.productDetails.name, product_image:this.state.productDetails.small_image, product_id:this.state.productDetails.id})).then(response => {
		 		if(response.data.status === "ok"){
		 			this.loadCartDetails();
		 			this.setState({cartSucces:'Cart Added Successfully'});
		 			this.top.loadCartDetails();
		 			helper.getshowSuccess('show');
		 			helper.gethideSuccess('');
		 		}
	        }).catch(function (error) {
	            console.log(error);
	        });
		}		
	}
	
	
	scrollStep() {
        if (window.pageYOffset === 0) {
            clearInterval(this.state.intervalId);
        }
        window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
    }
    scrollToTop() {
        let intervalId = setInterval(this.scrollStep.bind(this), this.props.delayInMs);
        this.setState({ intervalId: intervalId });
    }

	 render() {
	 	var currentLocation = {paths:'no'};
	 	return(
		 	<div>
		 		 <Top {...currentLocation} onRef={ref => (this.top = ref)}  />
		 		 {helper.getSuccess(this.state.cartSucces)}
				<div className="container_wrap">
					<div className="search_component">	

						<div className="offer_product">	
							<div className="offer-product-image">
								<div className="product-image-gallery">	
									<img src={(typeof this.state.productDetails.large_image!=='undefined' && this.state.productDetails.large_image!=='' && this.state.productDetails.large_image!==null)?this.state.productDetails.large_image:'assets/images/no-image.png'} alt="" />
								</div>
							</div>		
							<div className="offer-product-content">	
								<div className="pro-info">
									<h2>{this.state.productDetails.name}</h2>			
									<div className="satr_rating">
										<span><img src="assets/images/star.png" alt="" /></span><span className="count">(1,350)</span>
									</div>
									<h6>
									<span>Brand : {this.state.productDetails.brand}<span className="dot"> - </span></span>
									<span>Category : {this.state.productDetails.category}<span className="dot"> - </span></span>
									<span>Item model number : 164<span className="dot"> - </span></span>
										<br />
										<span>UPC : {this.state.productDetails.upc}</span></h6>
										
										<span>Weight : {(this.state.productDetails.weight!='')?this.state.productDetails.weight:''}</span>
									</div>
									{(this.state.showDesc===1)?this.state.productDescription:''}
											
								</div>		
								<div className="offer-product-pricing">	
									<div className="origin-totals">
										{(this.state.productprice>0)?<h4 className="charcoal">Base Price: ${helper.showPrice(this.state.productprice)}</h4>:''}
										
										{(this.state.productDescription!=='' )?<div className="action-group"><button className="primary-btn-large" onClick={this.showDesc.bind(this)}><span>View</span></button></div>:''}

									</div>
								</div>	
							</div>
							<div className="clearfix"></div>



							<div className="price-history">
								<div className="table-filters">
									{(this.state.productPriceLoadStatus===false)?<h1>{this.state.totalOffers} Offers</h1>:''}									
								</div>
								<div className="offer-table-component">
									<table className="table table-bordered">
										<thead>
											<tr>
												<th>Store</th>
												<th>Status</th>
												<th>Price</th>
												<th>Shipping Cost</th>
												<th>Est. Total</th>
												<th></th>
											</tr>
										</thead>
										<tbody>
											{(this.state.productPriceLoadStatus===false && this.state.totalOffers===0)?<tr><td colSpan="6">Whoo, We could not find any special offer for the product at the moment'</td></tr>:''}
											{(this.state.productPriceLoadStatus===true)?<tr><td colSpan="6"><BulletList /></td></tr>:''}
											{(this.state.productPriceLoadStatus===false)?this.state.amazonelist:''}
											{(this.state.productPriceLoadStatus===false)?this.state.googlelist:''}
											
											</tbody>
											</table>
											</div>
										</div>
									</div>
								</div>
								<Footer {...currentLocation} />

		 	</div>	
	 	);
	 }
}

export default Product;