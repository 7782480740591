/* eslint-disable */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Top from './Top';
import Footer from './Footer';
import axios from 'axios';
import { BulletList } from 'react-content-loader'
const config = require('./config');
//const lang = require('./language/lang-'+config.language);
const APIURL = config.path.apiUrl;
const loginUser = config.login_user;

class Productlist extends Component {
	constructor(props, context) {
	 	super(props, context);
	 	const urlParams = new URLSearchParams(this.props.location.search)
		const searchKeyword = urlParams.get('s');
		
        this.state = {
        	productList:[], loadProductList:'',brands:[], brandList:[], categories:[], categoryList:[], searchKeyword:searchKeyword, productLoadStatus:true

        };

	}
	componentWillMount() {
		this.listStores();
		this.scrollStep();
		if(this.state.searchKeyword!==null && typeof this.state.searchKeyword!='undefined') {
			this.loadProducts();	
		}
		else {
			this.setState({productLoadStatus:false});
		}
	 	
	}
	

	loadProducts() {
	 	axios.get(APIURL+'products/listProducts?searchKeyword='+this.state.searchKeyword).then((response) => {
	 		this.setState({productLoadStatus:false});
	 		if(response.data.status === "ok"){
				this.setState({productList:response.data.result});
				this.setState({loadProductList:this.loadProductList(response.data.result)});
				
	 		}
        });
	}
	
	scrollStep() {
        if (window.pageYOffset === 0) {
            clearInterval(this.state.intervalId);
        }
        window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
    }
    scrollToTop() {
        let intervalId = setInterval(this.scrollStep.bind(this), this.props.delayInMs);
        this.setState({ intervalId: intervalId });
    }

	loadProductList(productList) {
	 	var current = this;
	 	var brnads = [];
	 	var categories = [];
	 	const listItems =  Object.keys(productList).map(function(index){
	 		var currentProduct = current.state.productList[index];
	 		if(currentProduct.upc!='') {
		 		if(currentProduct.brand!=='') {
		 			brnads.push(currentProduct.brand);	
		 		}	 		
		 		if(currentProduct.category!=='') {
		 			categories.push(currentProduct.category);	
		 		}	 		
		 		return (
		 			<div key={index}  className="product_grid">
						<div className="product_img"><Link to={'/product/?id='+currentProduct.id}><img src={(typeof currentProduct.small_image!=='undefined' && currentProduct.small_image!=='')?currentProduct.small_image:'assets/images/no-image.png'} alt="" /></Link></div>
						<Link to={'/product/?id='+currentProduct.id}><h5>{currentProduct.name}</h5></Link>
						<div className="satr_rating">
							<span><img src="assets/images/star.png"  alt="" /></span><span className="count">(1,350)</span>
						</div>
						<div className="product_details">
							{ /* <h3 className="amt">$12.60</h3> */ }
							<h4 className="view"><Link to={'/product/?id='+currentProduct.id}>View Deals</Link></h4>
						</div>
					</div>
		 			);
	 		}

	 	});
	 	brnads = this.removeDups(brnads);
	 	this.setState({brands:brnads});
	 	this.setState({brandList:this.loadBrnads(brnads)});
	 	categories = this.removeDups(categories);
	 	this.setState({categories:categories});
	 	this.setState({categoryList:this.loadCategories(categories)});
	 	return listItems;
	 }

	 loadBrnads(brnads) {
	 	const listBrands =  brnads.map(function(brand, index){
	 		return (
	 			<div key={index}  className="custom_checkbox">
					<input type="checkbox" />
					<span>{brand}</span>
				</div>
	 			);
	 	});
	 	return listBrands;
	 }

	 loadCategories(categories) {
	  	const listCategories =  categories.map(function(category, index){
	 	return (
	 			<div key={index}  className="custom_checkbox">
					<input type="checkbox" />
					<span>{category}</span>
				</div>
	 			);
	 	});
	 	return listCategories;
	 }


	listStores() {
		axios.get(APIURL+'stores/liststores').then((response) => {
	 		if(response.data.status === "ok"){
				this.setState({storeList:response.data.result});
				this.setState({loadStoreList:this.loadStoreList(response.data.result)});
				
	 		}
        });		
	}

	loadStoreList(storeList) {
		var current = this;

	 	const listItems =  Object.keys(storeList).map(function(index){
	 		if(index<=3)  {	 			 
	 		var currentStore = current.state.storeList[index];

	 		return (
	 			<div key={index} className="store_grid">
	 				<div className="grid_sec">
						<div className="store_img"><img src={(typeof currentStore.image_link!=='undefined' && currentStore.image_link!=='')?currentStore.image_link:'assets/images/no-image.png'} alt={currentStore.name} /></div>
						<span>{currentStore.name}</span>		
						<div className="ribbon"><h4>View More</h4></div>
					</div>
				</div>
	 			);

	 		}
	 	});
	 	return listItems;
	}



	 removeDups(names) {
	  let unique = {};
	  names.forEach(function(i) {
	    if(!unique[i]) {
	      unique[i] = true;
	    }
	  });
	  return Object.keys(unique);
	}

	 render() {
	 	var currentLocation = {paths:'no', searchKeyword:this.state.searchKeyword};
	 	return(
		 	<div>
		 		 <Top {...currentLocation} onRef={ref => (this.top = ref)}  />
				<div className="container_wrap">
					<div className="search_component">						
						<div className="left_side">
							<div className="filter_panel">		
								
								<div className="btm-padd">
									 <h3 className="bold silver">Categories</h3>
									{ this.state.categoryList  }
								</div>
								<div className="btm-padd">
									<h3 className="bold silver">Brands</h3>
									{(this.state.productLoadStatus===true)?<BulletList />:''}
									{this.state.brandList}
								</div>		
							</div>
						</div>

						<div className="right_side">
							<div className="results_panel">	
								
								<div className="result_header">
									<h1>{(this.state.searchKeyword!=='' && this.state.searchKeyword!==null)?'Search Results for "'+this.state.searchKeyword+'"':''}</h1>
								</div>		
								<div className="store_list">
									{this.state.loadStoreList}
								
									<div className="clearfix"></div>			
								</div>
								<div className="product_list">
									{ /*<h2 className="search-info-title">10 store results</h2> */ }
									{(this.state.productLoadStatus===true)?<BulletList />:''}
									
									{this.state.loadProductList}
								</div>
								
							</div>
						</div>		
						
					</div>
				</div>
				<Footer {...currentLocation} />
		 	</div>	
	 	);
	 }
}

export default Productlist;